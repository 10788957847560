import React, { useEffect, useState } from 'react'
import Seo from './seo'
import TutorialAppWrapper from './TutorialAppWrapper'
import { Link, navigate } from 'gatsby'

interface TutorialLayout {
  children?: React.ReactNode
  title: string
  description: string | React.ReactNode
  buttonText: string | undefined
  buttonLink: string | undefined
  imageSrc: string
  step?: string
  firstRadioText?: string
  secondRadioText?: string
  firstRadioLink?: string
  secondRadioLink?: string
}

function TutorialLayout(props: TutorialLayout) {
  const [option, setOption] = useState(0)
  const [disabled, setDisabled] = useState(true)
  const { children, title, imageSrc, description, buttonText, buttonLink, firstRadioText, secondRadioText, step, firstRadioLink, secondRadioLink } = props

  useEffect(() => {
    if (!firstRadioLink && !firstRadioText && !secondRadioLink && !secondRadioText && buttonLink) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [])


  return (
    <div>
      <Seo title={'Tutorial platforma Ara'} />
      <TutorialAppWrapper>
        <img
          style={{ marginBottom: 32 }}
          src={imageSrc}
          alt="button_app_store" />
        {step && <h1 style={{ fontSize: 12, fontWeight: 600, color: "#2E3338", lineHeight: "16px" }}>Pasul {step}</h1>}
        {title &&
          <h1 style={{ padding: 0, margin: 0, color: "#2E3338", lineHeight: "32px", fontSize: 24, fontFamily: "Inter" }}>{title}</h1>
        }
        <p style={{ fontSize: 16, color: "#2E3338", lineHeight: "24px", fontFamily: "Inter", marginTop: 12 }}>{description}</p>
        {
          firstRadioText && secondRadioText && (
            <div>
              <div
                onClick={() => {
                  setOption(1);
                  setDisabled(false)
                }}
                style={{
                  border: option === 1 ? "2px solid #0673E0" : "1px solid #ABB2BA",
                  backgroundColor: option === 1 ? "#EEF5FC" : "#fff",
                  borderRadius: 6,
                  paddingLeft: 16,
                  height: 40,
                  alignItems: "center",
                  display: "flex",
                  cursor: "pointer",
                }}
              >
                <input
                  type="radio"
                  id="radio1"
                  name="customRadio"
                  checked={option === 1}
                  onChange={() => { setOption(1); setDisabled(false) }}
                  style={{ display: "none" }}
                />
                <label
                  htmlFor="radio1"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    position: "relative",
                  }}
                >
                  <span
                    style={{
                      width: 16,
                      height: 16,
                      borderRadius: "50%",
                      border: `2px solid ${option === 1 ? "#0673E0" : "#73808C"}`,
                      backgroundColor: "#fff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: 12,
                      boxSizing: "border-box",
                    }}
                  >
                    {option === 1 && (
                      <span
                        style={{
                          width: 8,
                          height: 8,
                          borderRadius: "50%",
                          backgroundColor: "#0080FF",
                        }}
                      ></span>
                    )}
                  </span>
                  {firstRadioText}
                </label>
              </div>

              <div
                onClick={() => {
                  setOption(2);
                  setDisabled(false)
                }}
                style={{
                  marginTop: 16,
                  border: option === 2 ? "2px solid #0673E0" : "1px solid #ABB2BA",
                  borderRadius: 6,
                  backgroundColor: option === 2 ? "#EEF5FC" : "#fff",
                  paddingLeft: 16,
                  height: 40,
                  alignItems: "center",
                  display: "flex",
                  cursor: "pointer",
                }}
              >
                <input
                  type="radio"
                  id="radio2"
                  name="customRadio"
                  checked={option === 2}
                  onChange={() => { setOption(2); setDisabled(false) }}
                  style={{ display: "none" }}
                />
                <label
                  htmlFor="radio2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    position: "relative",
                  }}
                >
                  <span
                    style={{
                      width: 16,
                      height: 16,
                      borderRadius: "50%",
                      border: `2px solid ${option === 2 ? "#0673E0" : "#73808C"}`,
                      backgroundColor: "#fff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: 12,
                      boxSizing: "border-box",
                    }}
                  >
                    {option === 2 && (
                      <span
                        style={{
                          width: 8,
                          height: 8,
                          borderRadius: "50%",
                          backgroundColor: "#0080FF",
                        }}
                      ></span>
                    )}
                  </span>
                  {secondRadioText}
                </label>
              </div>
            </div>
          )
        }
        {children}
        {
          buttonLink &&
          <Link to={option === 1 && firstRadioLink ? firstRadioLink : option === 2 && secondRadioLink ? secondRadioLink : buttonLink}>
            <button disabled={disabled} style={{ marginBottom: 24, marginTop: 124, width: "100%", backgroundColor: disabled ? "#E3E5E8" : "#0F8A0F", height: 48, border: "none", cursor: disabled ? "none" : "pointer", color: disabled ? "#8F99A3" : "white", borderRadius: 8, fontSize: 16, fontWeight: 400, fontFamily: "Inter" }}>{buttonText ? buttonText : "Urmatorul pas ->"}</button>
          </Link>
        }

      </TutorialAppWrapper>
    </div >
  )
}

export default TutorialLayout